import Vue from "vue";
import VTooltip from "v-tooltip";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.ts";
import DropdownMenu from "v-dropdown-menu/vue2";
import Vuetify from 'vuetify';

// import Vuelidate from 'vuelidate'

Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.use(DropdownMenu);
Vue.use(Vuetify);
const vuetify = new Vuetify({});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
